import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitToggle(cssVars: CSSStyleDeclaration) {
  return {
    toggle: {
      color: getCssValue('--ax-toggle-color-default', cssVars),
      radioInner: getCssValue('--ax-toggle-switch-radio-inner', cssVars),
      sizeInner: getCssValue('--ax-toggle-size-switch-inner', cssVars),
      spacing: getCssValue('--ax-toggle-switch-base-padding', cssVars),
      spacingInner: getCssValue('--ax-toggle-spacing-switch-inner', cssVars),
      thumbColor: getCssValue('--ax-toggle-color-thumb', cssVars),
      basePadding: getCssValue('--ax-toggle-switch-base-padding', cssVars),
      transitionSpeed: getCssValue('--ax-toggle-transition-speed', cssVars),
      uncheckedColor: getCssValue('--ax-toggle-color-unchecked', cssVars),
      width: getCssValue('--ax-toggle-width-switch', cssVars),
      widthBorder: getCssValue('--ax-toggle-width-border', cssVars),
      disabledBorderColor: getCssValue('--ax-toggle-color-disabled-border', cssVars),
      disabledCheckedColor: getCssValue('--ax-toggle-color-disabled-checked', cssVars),
      disabledColor: getCssValue('--ax-toggle-color-disabled', cssVars),
      disabledOpacity: getCssValue('--ax-toggle-opacity-disabled', cssVars),
      enabledOpacity: getCssValue('--ax-toggle-opacity-enabled', cssVars)
    }
  };
}
